.live-chat {
    width: 100%;
    
    background: linear-gradient(to bottom, rgba(0, 0, 0), rgba(255, 250, 250));
    display: flex;
    flex-direction: column;
    overflow: auto;
    scroll-behavior: smooth;
    transition: all 0.3s;
    
}
.chat-header{
    display: flex;
    justify-content: space-between;
    padding: 30px 20px;
    position: relative;
    
}
.chat-header img{
    width: 160px;
    
}
.chat-body .chat-body-header .support-team img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: -3px;
    border: 3px solid #222;
}
.chat-body .chat-body-header .support-team{
    display: flex;
    justify-content: start;
    margin: 0px 20px;
}
.chat-body-header-title{
    padding: 20px;
}
.chat-body-header-title h1,h2{
    overflow-wrap: break-word;
    font-weight: 700;
    color: rgb(255, 255, 255);
    font-size: 2rem;
}
.chat-username{
    opacity: 0.7;
}
.live-chat{
    font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size-adjust: none;
    font-size: 100%;
    font-style: normal;
    letter-spacing: normal;
    font-stretch: normal;
    font-variant: normal;
    font-weight: normal;
    text-align: left;
    text-align-last: initial;
    text-decoration: none;
    text-emphasis: none;
    text-indent: 0px;
    text-shadow: none;
    text-transform: none;
    margin: 0px;
    padding: 0px;
    line-height: inherit;
}
.chat-card{
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin: 10px;
}
.chat-card span{
    font-size: 0.7rem;
    font-weight: 700;
    color: #000;
}
.chat-history{
   display: flex;
   
   justify-content: space-between;
   align-items: center;
   width: 100%;
   padding: 5px;
   
}
.chat-history img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}
.chat-history-body{
    display: flex;
    
}
.chat-history-body span{
    font-size: 0.8rem;
    font-weight: 500;
    color: #555;
}
.chat-history-body p{
    font-size: 0.8rem;
    font-weight: 450;
    color: #666;
}
.text-dark{
    color: #000;
}
.live-chat .icon{
    font-size: .7rem;
}
.chat-card-body-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.w-100{
    width: 100% !important;
}
.chat-card.promotion-cd p{
    font-size: 0.8rem;
    font-weight: 300;
    color: #888;
    list-style: .9rem;
    

}

.chat-footer{
    height: 64px;
    position: fixed;
    bottom: 0;
    z-index: 100000;
    background: #fff;
    width: 100%;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 -1px 10px rgba(32, 32, 32, 0.7);

}
.chat-nav{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    
    
}
.chat-nav a{
    text-decoration: none;
    color: #444;
}
.chat-nav li{
    text-align: center;
    font-size: 1rem;
}
.chat-nav p{
    color: #444;
}
.chat-body{
    margin-bottom: 64px;
}
.text-white{
    color: #fff;
}
.chat-historys-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #333;

} 
.chat-historys{
    width: 100%;
    height: 100vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.3s;
}
.chat-list-contnet{
    color: #000 !important;
    font-size: .8rem;
}
.chat-list-body{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}
.chat-list-body img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
} 
.chat-his-body{
    overflow: auto;
    height: 100%;
    margin-bottom: 65px;
} 
.d-flex{
    display: flex;
}
.chat-list-msg{
    justify-content: center;
    align-items: center;
}
.chat-list-body .icon{
    font-size: .7rem;
}
.send-us{
    position: fixed;
    bottom: 64px;
    background: rgb(255, 255, 255, 0.5);
    width: 100%;
    height: 40px;
    text-align: center;
}
.send-us button{
    text-decoration: none;
    color: #fff;
    background: #333;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 14px;
}
.send-us svg{
    padding-left: 10px;
}

/* chat box */
.chat-box-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #333;
    color: #fff;
}
.chat-box{
    width: 100%;
    height: 100vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.3s;
}
.chat-avatar{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}
.chat-user{
    display: flex;
    justify-content: start;
    align-items: center;
}
.chat-user span{
    font-size: 1rem;
    font-weight: 700;
    color: #ddd;
}
.chat-user p{
    
    font-size: 0.8rem;
    font-weight: 500;
    color: #888;
}
.chat-box-header .icon{
    font-size: .7rem;
    margin-right: 15px;
    color: #ccc;
}
.chat-active{
    position: absolute;
    left: 70px;
    top: 50px;
    background: #1ff503;
    padding: 4px;
    border-radius: 50%;
}
.chat-box-body {
    display: flex;
    flex-direction: column; 
   
    margin-bottom: 56px;
   
}

.chat-box-msg {
    margin: 5px;
    max-width: 70%;
    font-size: .8rem;
    line-height: 1rem;
    padding: 10px;
    border-radius: 7px;
}

.chat-box-msg.left-box {
    align-self: flex-start; /* Aligns to the left */
    color: #000;
    background: #ccc;
}

.chat-box-msg.right-box {
    align-self: flex-end; /* Aligns to the right */
    color: #fff;
    background: #333;
}
.chat-box-footer{
    height: 55px;
    background: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    padding: 7px;
    align-items: center;
    justify-content: space-between;
}
.chat-box-footer input{
    width: 80%;
    border-radius: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    outline: none;
}
.chat-box-footer button{
    background: #333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 7px;
    cursor: pointer;
}

.chat-box-footer button:active{
    background: #1ff503;
}
.chat-box-footer .a-icon{
    font-size: 1.5rem;
    color: #333;
    margin: 2px;
}
.typing-indicator {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    margin: 10px 5px;
    
    
}

.typing-indicator span {
    width: 5px;
    height: 5px;
    background: #333;
    border-radius: 50%;
    animation: typing 1s infinite;
}

.typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes typing {
    0%, 100% {
        transform: scale(0.8);
        opacity: 0.5;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
}

.chat-tongole{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #111;
    color: #fff;
    font-size: 26px;
    position: fixed;
    right: 10px;
    bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
}
.chat-tongole img{
    width: 26px;
}
a{
    text-decoration: none;
}
.chat-list-content span{
    font-size: 0.8rem;
    font-weight: 500;
    color: #555;
}
